import "./Footer.scss";

const Footer: React.FC<any> = () => {
  return (
    <footer className="Footer">
      <div>
        <p>
          This site was designed in <span>Figma</span> and Developed in{" "}
          <span>React + Typescript + SCSS</span> by yours <span>truly</span>.
        </p>
        <p className="Footer__Copyright">
          Copyright &copy; {new Date().getFullYear()}
        </p>
      </div>
    </footer>
  );
};

export default Footer;
