import Carousal from "./Carousal/Carousal";
import ICarousal from "./Carousal/CarousalModels/CarousalModels";
import "./Portfolio.scss";

const PORTFOLIO_DATA: ICarousal[] = [
  {
    title: "Merchant Dashboard",
    duration: "2011",
    description: `Build a modern responsive site to visualize the sales data of major us retailer. The primary purpose being to help store managers visualise the sales data in graphical format with customizable metrics which helped them both run their stores effectively as well as present the same in weekly sales review.
    This was built in modern HTML 5 framework (Sencha Touch) which was still at infancy at the time of implementation in late 2011, we faced multiple challenges especially with limited support for apple devices using safari browser which had minimal support at that time.  But my team worked relentlessly and delivered the requirements in a short period of 3 months which was well received and brought in a lot of new business to our account
`,
    skills: [
      "JavaScript",
      "HTML5/CSS3",
      "Sencha Ext JS",
      "Java",
      "Apache",
      "Oracle",
    ],
  },
  {
    title: "Centralized Dashboard",
    duration: "2012 - 2013",
    description: `Build a centralized dashboard to manage all the existing applications of a major UK medical research company. They wanted us to create responsive site which acted as centralized hub to all their existing sites and manage the authentication and authorization at the hub level.
    We build the site with jQuery and Bootstrap and enabling single sign on with Microsoft Active Directory.  Later as we progressed, we redesigned most of their applications to make them more modernized.
    `,
    skills: [
      "jQuery",
      "HTML5/CSS3",
      "Bootstrap",
      ".Net Framework",
      "SQL Server",
    ],
  },
  {
    title: "Asset Management",
    duration: "2013 - 2015",
    description: `Build a secure asset management site to manage financial assets of high net-worth individuals by a large asset management company based out of United States of America. We created a responsive website using AngularJS and PhoneGap to manage Accounts, Stocks, Bonds and Gold in a centralized mobile applications and web portal. This includes multiple integrations with third party applications using secure keys and encrypted data.`,
    skills: ["Angular JS", "jQuery", "PhoneGap", "Java", "Oracle"],
  },
  {
    title: "Insurance Company Portals",
    duration: "2015 – 2018",
    description: `Build multiple portals and dashboards to handle complete process from marketing to claim processing and fraud detection for a leading Insurance aggregator based out of United States of America and United Kingdom. We build a common workflow tool which generated the workflow for a given team from configuration setup in a centralized application. All the front end was handled with Angular. We also used Machine Learning models to classify the submitted documents into rightful categories while doing fraud detection using TensorFlow deep learning neural network.`,
    skills: [
      "Angular",
      ".Net",
      " Scikit Learn",
      "Tensorflow 2.0",
      "SQL Server",
    ],
  },
  {
    title: "Insurance Company Site",
    duration: "2018 – 2019",
    description: `Created reusable components for multiple standard layouts using React, SCSS and CSS Grids as requested by a leading Insurance company who was sponsor for a legendary premier league team based in Manchester. These components were reused by the marketing team in a popular CMS tool to maintain their primary site.`,
    skills: ["React", "HTML/SCSS", "CSS Grids", "CMS Tool"],
  },
  {
    title: "Waste Management Company",
    duration: "2019 - 2023",
    description: `Redesign their existing products used to track and maintain valets task scheduling, completion and time punches of a popular waste management company based out of United States of America which is nationally recognized. We also developed their resident facing applications which are customer facing and help scale their business multi fold growth in the last few years.  We also integrated their CRMS and HRMS tools in to the new products making it one source all application to manage their day-to-day business. On top of all this we also migrated them fully to cloud using Azure`,
    skills: [
      "Angular",
      "React JS",
      "Kendo",
      "PWA",
      ".NET Core",
      "Xamarin",
      "Microservices",
      "Azure",
      "SQL Server",
    ],
  },
  {
    title: "AI Jeannie Plugin",
    duration: "2024 - Present",
    description: `AI Jeannie leverages generative AI to boost productivity by swiftly converting project definitions into detailed epics and user stories, complete with structured, Gherkin-based acceptance criteria that align with project details. This tool also generates sequence diagrams, incorporates images for additional context, and supports multiple providers, making it a comprehensive solution for project management.`,
    skills: [
      "Generative AI",
      "React JS",
      "Node JS",
      "langchain.js",
      "Atalassian Forge App",
    ],
  },
  {
    title: "AI Jeannie Standalone",
    duration: "2024 - Present",
    description: `AI Jeannie leverages generative AI to enhance productivity by quickly transforming project definitions into detailed epics and user stories. It streamlines the creation of structured, Gherkin-based acceptance criteria, ensuring each user story aligns with project details. The tool also generates UML diagrams, incorporates images and documents for additional context, and supports multiple ALM systems, knowledge bases, and QMS platforms, making it a comprehensive solution for project management.`,
    skills: [
      "Generative AI",
      "Vite + React JS",
      "EPAM UUI",
      "Python",
      "Langchain",
      "OpenSearch",
      "Unstructured.io",
    ],
  },
].reverse();

const Portfolio: React.FC<any> = () => {
  return (
    <section className="Portfolio">
      <header>
        <h1>Case Studies of Projects spanning over my career</h1>
      </header>
      <section>
        <Carousal data={PORTFOLIO_DATA} />
      </section>
    </section>
  );
};

export default Portfolio;
