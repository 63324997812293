import { useEffect, useRef, useState } from "react";
import "./Loader.scss";

type LoaderProps = {
  message: string;
};

const Loader: React.FC<LoaderProps> = (props: LoaderProps) => {
  const rangeRef: any = useRef(null);
  const [value, setValue] = useState(0);

  useEffect(() => {
    const interval: any = setInterval(() => {
      try {
        if (rangeRef.current && rangeRef.current.value < 50) {
          setValue((state) => {
            return state + 25;
          });
        }
      } catch (e) {
        clearInterval(interval);
      }
    }, 1000);

    //Clearing the interval
    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <>
      <div className="Loader">
        <div>
          <br />
          <input
            type="range"
            ref={rangeRef}
            min="0"
            max="50"
            disabled
            value={value}
          ></input>
        </div>
      </div>
    </>
  );
};

export default Loader;
