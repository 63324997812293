import { useEffect, useState } from "react";
import "./Contact.scss";
import Quill from "quill";
import Loader from "../Common/Loader/Loader";
import { createPortal } from "react-dom";

(window as any).quill = undefined;

const Contact: React.FC<any> = () => {
  const [email, setEmail] = useState("");
  const [showArchitecture, setShowArchitecture] = useState(false);
  const [isFetching, setFetching] = useState(false);

  useEffect(() => {
    const element = document.querySelector(".ql-toolbar.ql-snow");
    if (!element) {
      (window as any).quill = new Quill("#editor-container", {
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"], // toggled buttons
            ["blockquote", "code-block"],

            [{ header: 1 }, { header: 2 }], // custom button values
            [{ list: "ordered" }, { list: "bullet" }],
            [{ script: "sub" }, { script: "super" }], // superscript/subscript
            [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
            [{ direction: "rtl" }], // text direction

            [{ size: ["small", false, "large", "huge"] }], // custom dropdown
            [{ header: [1, 2, 3, 4, 5, 6, false] }],

            [{ color: [] }, { background: [] }], // dropdown with defaults from theme
            [{ font: [] }],
            [{ align: [] }],

            ["clean"], // remove formatting button
          ],
        },
        placeholder: "Compose an epic...",
        theme: "snow", // or 'bubble'
      });
    }
  }, []);

  const showFullImageHandler = () => {
    setShowArchitecture((prevState: boolean) => {
      return true;
    });
  };

  const hideFullImageHandler = () => {
    setShowArchitecture((prevState: boolean) => {
      return false;
    });
  };

  const onSubmitHandler = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    event.stopPropagation();

    const totalRows = (window as any).quill
      ? (window as any).quill.getLength()
      : 0;

    let content = undefined;
    let contentWithFormating = undefined;
    let contentText = undefined;

    if (totalRows > 1) {
      content = (window as any).quill
        ? (window as any).quill.getContents()
        : "";
      contentWithFormating = (window as any).quill.root.innerHTML;
      contentText = (window as any).quill
        ? (window as any).quill.getText()
        : "";
    } else {
      content = "";
      contentWithFormating = "";
      contentText = "";
    }

    if (email && content && contentWithFormating && contentText) {
      setFetching(true);
      post("/api/SendGrid/SendEmail", {
        Email: email,
        Message: contentText,
        HtmlFormated: contentWithFormating,
        OriginalData: JSON.stringify(content),
      }).then(
        (data: any) => {
          (window as any).quill.setText("\n");
          setFetching(false);
        },
        () => {
          setFetching(false);
        }
      );
    }
  };

  // Example POST method implementation:
  async function post(url = "", data = {}) {
    // Default options are marked with *
    const response = await fetch(url, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      redirect: "follow", // manual, *follow, error
      referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      body: JSON.stringify(data), // body data type must match "Content-Type" header
    });
    return response.json(); // parses JSON response into native JavaScript objects
  }

  let architecureElement = null;
  if (showArchitecture) {
    architecureElement = (
      <div
        className="Contact__Show__Architecture"
        onClick={() => hideFullImageHandler()}
      >
        {/* <i className="close" onClick={() => hideFullImageHandler()}></i> */}
        <div></div>
      </div>
    );
  }

  return (
    <section className="Contact" id="contact">
      {isFetching &&
        createPortal(<Loader message="sending email..." />, document.body)}
      <header>
        <h1>Reach out just to say hello!</h1>
      </header>
      {architecureElement}
      <section>
        <div>
          <form
            className="Contact__Form"
            action=""
            onSubmit={(event) => onSubmitHandler(event)}
            noValidate
          >
            <div>
              <p>Email</p>
              <input
                type="email"
                name="email"
                placeholder="Type your email."
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div>
              <p>Message</p>
              <div className="Contact__Editor">
                <div id="editor-container" style={{ background: "#fff" }}></div>
              </div>
            </div>
            <div className="Contact__Form__Buttons">
              <input type="submit" value="Say Hello..." />
            </div>
          </form>
        </div>
        <div className="Contact__Form__Links">
          <ul>
            <li>
              <a
                href="https://www.linkedin.com/in/jitendra-santosh-varma-kosuri-93960626"
                target="_blank"
                rel="noreferrer"
              >
                LinkedIn
              </a>
            </li>
            <li>
              <a
                href="https://github.com/JSantoshVarmaKosuri"
                target="_blank"
                rel="noreferrer"
              >
                Github
              </a>
            </li>
            <li>
              <a
                href="https://onedrive.live.com/view.aspx?resid=3DBEF06F4BB1EDAF!1724&ithint=file%2cdocx&authkey=!AETYZNT4civPPPk"
                target="_blank"
                rel="noreferrer"
              >
                Resume
              </a>
            </li>
            <li>
              <a
                href="https://www.figma.com/file/enUDmlHpFVdSkHvLKgRLYO/Portfolio-v4.0?type=design&node-id=105%3A170&mode=dev"
                target="_blank"
                rel="noreferrer"
              >
                Inspiration
              </a>
            </li>
            <li>
              <a
                href="javascript:void(0);"
                onClick={() => {
                  return showFullImageHandler();
                }}
              >
                Architecture
              </a>
            </li>
          </ul>
        </div>
      </section>
    </section>
  );
};

export default Contact;
