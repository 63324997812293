import Accordian from "./Accordian/Accordian";
import IAccordian from "./Accordian/Models/AccordianModels";
import "./Career.scss";

const CARRIER_DATA: IAccordian[] = [
  {
    title: "Solution Architect",
    subtitle: "EPAM Technologies, Gen AI and Digital Engagement Practice",
    description: [
      "Solution Architect for multiple products with emphasis on building applications around Generative AI and Digital Engagement.",
      "Mentor and guide individuals in the Generative AI space.",
      "As an individual contributor, delivered multiple applications across various technology stacks and platforms.",
      "Implemented a strict code review process and streamlined the project structures to follow the latest coding practices and guidelines.",
    ],
    start: new Date(2024, 0, 12),
    end: null,
    text: "www.epam.com",
    link: "https://www.epam.com/",
    location: "Hyderabad",
    colors: ["#4E7FFF", "#4E7FFF66"],
  },
  {
    title: "User Experience Engineering Associate Manager",
    subtitle: "Accenture, Product and Platform Engineering",
    description: [
      "Led a twenty-member team as a SCRUM master and successfully delivered 25 sprints and 5 releases.",
      "Successfully planned, reviewed, and created solutions for multiple applications as a solution architect.",
      "As an individual contributor, delivered multiple web components with full PWA and responsive support.",
      "Trained and upskilled close to 10 juniors to full-fledged developers in .Net, Angular, and HTML/CSS.",
      "Implemented a strict code review process and streamlined the project structures to follow the latest coding practices and guidelines.",
      "Contributed towards recruitment by conducting interviews and creating case studies for the screening process.",
      "Led the client-side communication to keep the stakeholders and developers in sync with evolving requirements.",
    ],
    start: new Date(2021, 6, 1),
    end: new Date(2023, 6, 31),
    text: "www.accenture.com",
    link: "https://www.accenture.com",
    location: "Hyderabad",
    colors: ["#B14EFF", "#B14EFF66"],
  },
  {
    title: "Principal Engineer",
    subtitle: "Pramati Technologies Pvt Limited, Imaginea",
    description: [
      "Conducted code reviews and ensured coding practices and guidelines were followed meticulously.",
      "As an individual contributor, ensured all deliveries were on time and met the highest quality requirements.",
      "Upskilled and trained many developers who were onboarded as freshers.",
      "Contributed towards recruitment by conducting interviews.",
      "Conducted multiple sessions at a company level to showcase evolving technologies and inspire others to grow in their careers.",
    ],
    start: new Date(2015, 4, 18),
    end: new Date(2021, 6, 1),
    text: "www.pramati.com",
    link: "https://www.pramati.com",
    location: "Hyderabad",
    colors: ["#4E7FFF", "#4E7FFF66"],
  },
  {
    title: "Senior Developer Mobility",
    subtitle: "Cognizant Technology Solutions, Mobile First",
    description: [
      "As an individual contributor, ensured deliverables were completed on time and reviewed by leads.",
      "Trained and upskilled myself constantly with unwavering zeal to master my craft.",
      "Joined as a fresher and was promoted to Senior Developer in a very short time due to my hard work and ingenuity.",
    ],
    start: new Date(2011, 4, 31),
    end: new Date(2015, 4, 18),
    text: "www.cognizant.com",
    link: "https://www.cognizant.com",
    location: "Chennai",
    colors: ["#B14EFF", "#B14EFF66"],
  },
];

const Accordians = CARRIER_DATA.map((data: IAccordian, index: number) => {
  return <Accordian key={index} data={data} />;
});

const Career: React.FC<any> = () => {
  return (
    <section className="Career">
      <header>
        <h1>Professional Career</h1>
      </header>
      <section>{Accordians}</section>
    </section>
  );
};

export default Career;
