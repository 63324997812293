import React from "react";
import "./scss/App.scss";
import Header from "./Components/Header/Header";
import Hero from "./Components/Hero/Hero";
import Career from "./Components/Career/Career";
import Skills from "./Components/Skills/Skills";
import Portfolio from "./Components/Portfolio/Portfolio";
import Showcase from "./Components/Showcase/Showcase";
import Timeline from "./Components/Timeline/Timeline";
import Contact from "./Components/Contact/Contact";
import Footer from "./Components/Footer/Footer";

function App() {
  return (
    <div className="App">
      <div></div>
      <section className="App__Content">
        <Header />
        <Hero />
        <Career />
        <Skills />
        <Portfolio />
        <Showcase />
        <Timeline />
        <Contact />
        <Footer />
        <div />
      </section>
      <div></div>
    </div>
  );
}

export default App;
