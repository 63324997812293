import { useEffect, useState } from "react";
import "./Carousal.scss";
import ICarousal from "./CarousalModels/CarousalModels";

interface ICarousalProps {
  data: ICarousal[];
}

const Carousal: React.FC<ICarousalProps> = (props: ICarousalProps) => {
  const [carousalState, setCarousalState] = useState<any>({
    index: 0,
    lastClick: null,
  });

  useEffect(() => {
    setTimeout(() => {
      if (carousalState.lastClick) {
        let elements: NodeListOf<HTMLElement> | null =
          document.querySelectorAll(`.Carousal__Card`);
        if (elements && elements.length) {
          elements.forEach((node: HTMLElement) => {
            if (carousalState.lastClick === "left") {
              node.classList.remove("right");
              node.classList.add("left");
            } else {
              node.classList.remove("left");
              node.classList.add("right");
            }
          });
        }
      }
    });
  });

  let carousalCardClass = "Carousal__Card";
  if (carousalState.lastClick === "right") {
    carousalCardClass = `${carousalCardClass} reset`;
  }

  const getData = (list: any[], index: number, button: string | null) => {
    const len = list.length;
    const current = index;
    const prev = current - 1 >= 0 ? current - 1 : len - 1;
    const prev_prev = prev - 1 >= 0 ? prev - 1 : len - 2;
    const next = current + 1 <= len - 1 ? current + 1 : 0;
    const next_next = next + 1 <= len - 1 ? next + 1 : 1;

    // console.info(button, {
    //   prev_prev: prev_prev,
    //   prev: prev,
    //   current: current,
    //   next: next,
    //   next_next: next_next,
    // });

    if (button === null) {
      return [list[prev_prev], list[prev], list[current], list[next]];
    } else {
      if (button === "right") {
        return [list[prev_prev], list[prev], list[current], list[next]];
      } else {
        return [list[prev], list[current], list[next], list[next_next]];
      }
    }
  };

  const Cards = getData(
    props.data,
    carousalState.index,
    carousalState.lastClick
  ).map((item: ICarousal, idx: number) => {
    return (
      <section
        className={carousalCardClass}
        key={`${carousalState.index}_${idx}`}
      >
        <p>
          <span className="one">{"<section>"}</span>
        </p>
        <h4>
          <span className="one">{"<h3>"}</span> <strong>{item.title}</strong>{" "}
          <span className="one">{"</h3>"}</span>
        </h4>
        <h4>
          <span className="one">{"<h4>"}</span> <strong>{item.duration}</strong>{" "}
          <span className="one">{"</h4>"}</span>
        </h4>
        <p>
          <span className="one">{"<p>"}</span> <br />{" "}
          <span className="codespacing">{item.description}</span> <br />{" "}
          <span className="one">{"</p>"}</span>
        </p>
        <p>
          <span className="one">{"</section>"}</span>
        </p>
        <div className="Carousal_Tags">
          {item.skills.map((skill: string, skillIndex: number) => {
            return <div key={skillIndex}>{skill}</div>;
          })}
        </div>
      </section>
    );
  });

  const previousHandler = () => {
    setCarousalState((prevState: any) => {
      let newIndex = prevState.index - 1;
      if (newIndex < 0) {
        newIndex = props.data.length - 1;
      }

      // console.info("Previous Called.", {
      //   index: newIndex,
      //   lastClick: "left",
      // });

      return {
        index: newIndex,
        lastClick: "left",
      };
    });
  };

  const nextHandler = () => {
    setCarousalState((prevState: any) => {
      let newIndex = prevState.index + 1;
      if (newIndex > props.data.length - 1) {
        newIndex = 0;
      }

      // console.info("Next Called.", {
      //   index: newIndex,
      //   lastClick: "right",
      // });

      return {
        index: newIndex,
        lastClick: "right",
      };
    });
  };

  return (
    <section className="Carousal">
      <div className="Carousal__Buttons">
        <button onClick={() => previousHandler()}>
          <i className="left"></i>{" "}
        </button>
        <div></div>
        <button onClick={() => nextHandler()}>
          <i className="right"></i>{" "}
        </button>
      </div>
      <section className="Carousal__Content">{Cards}</section>
    </section>
  );
};

export default Carousal;
