import { useEffect } from "react";
import "./Header.scss";

const Header: React.FC<any> = () => {
  useEffect(() => {
    const handleScroll = (event: any) => {
      const target = event.currentTarget;
      const nav = document.querySelector("nav.Header__Navigation > ul");
      if (target && target.scrollY > 100) {
        if (nav) {
          nav.classList.add("side");
        }
      } else {
        if (nav) {
          nav.classList.remove("side");
        }
      }
    };

    window.addEventListener("scroll", handleScroll);

    // root is the browser viewport / screen
    const portfolioObserver = new IntersectionObserver(
      function (entries) {
        for (let i = 0; i < entries.length; i++) {
          // since there is a single target to be observed, there will be only one entry
          if (entries[i]["isIntersecting"] === true) {
            if (entries[i]["intersectionRatio"] > 0.6) {
              const id: string = entries[i].target.id;
              const anchorElements: NodeListOf<HTMLAnchorElement> | null =
                document.querySelectorAll("nav.Header__Navigation > ul a");
              if (!!anchorElements && anchorElements.length) {
                anchorElements.forEach((ele: HTMLAnchorElement) => {
                  ele.parentElement!.classList.remove("active");
                  if (ele.innerText.toLowerCase() === id) {
                    ele.parentElement!.classList.add("active");
                  }
                });
              }
            }
          }
        }
      },
      { threshold: [0.6] }
    );

    const about = document.querySelector("#about");
    const skills = document.querySelector("#skills");
    const timeline = document.querySelector("#timeline");
    const contact = document.querySelector("#contact");

    if (about) portfolioObserver.observe(about);
    if (skills) portfolioObserver.observe(skills);
    if (timeline) portfolioObserver.observe(timeline);
    if (contact) portfolioObserver.observe(contact);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const toggleMenu = (event: any) => {
    let element: any = event.currentTarget ? event.currentTarget : null;
    if (element) {
      const header = element.parentElement;
      if (header) {
        header.classList.toggle("active");
      }
    }
  };

  const bringIntoView = (id: string, top = true) => {
    const section: HTMLElement | null = document.querySelector(`#${id}`);
    if (!!section) {
      section.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
    }
  };

  return (
    <header className="Header">
      <div className="Header__Menu" onClick={(e) => toggleMenu(e)} />
      <div className="Header__Logo" />
      <nav className="Header__Navigation">
        <ul>
          <li className="active">
            <a href="javascript:void(0)" onClick={() => bringIntoView("about")}>
              About
            </a>
          </li>
          <li>
            <a
              href="javascript:void(0)"
              onClick={() => bringIntoView("skills")}
            >
              Skills
            </a>
          </li>
          <li>
            <a
              href="javascript:void(0)"
              onClick={() => bringIntoView("timeline")}
            >
              Timeline
            </a>
          </li>
          <li>
            <a
              href="javascript:void(0)"
              onClick={() => bringIntoView("contact")}
            >
              Contact
            </a>
          </li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;
