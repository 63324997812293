import { useState } from "react";
import "./Accordian.scss";
import IAccordian from "./Models/AccordianModels";

interface IAccordianProps {
  data: IAccordian;
}

const Accordian: React.FC<IAccordianProps> = (props: IAccordianProps) => {
  const [open, setOpen] = useState<boolean>(false);
  const accordianHandler = () => {
    setOpen((prevSate) => {
      return !prevSate;
    });
  };
  const openClass: string = open ? "minus" : "plus";
  const openAccordianClass: string = open ? "Accordian open" : "Accordian";

  let background: string = "#dc6916";
  let backgroundDark: string = "#dc691666";
  if (props.data.colors) {
    background = props.data.colors[0];
    backgroundDark = props.data.colors[1];
  }

  return (
    <section className={openAccordianClass}>
      <header
        className="Accordian__Header"
        style={{ background: background }}
        onClick={() => {
          accordianHandler();
        }}
      >
        <div>
          <h1>{props.data.title}</h1>
          <i className={openClass} />
        </div>
        <div>
          <h2>{props.data.subtitle}</h2>
          <div>
            {props.data.start.toLocaleDateString(undefined, {
              year: "numeric",
              month: "long",
            })}{" "}
            -{" "}
            {props.data.end
              ? props.data.end.toLocaleDateString(undefined, {
                  year: "numeric",
                  month: "long",
                })
              : "Present"}
          </div>
        </div>
      </header>
      <div className="Accordian__Body" style={{ background: backgroundDark }}>
        <div className="Accordian__Body_Title">
          <div>
            <i className="location"></i>
            <p>{props.data.location}</p>
          </div>
          <div>
            <i className="link"></i>
            <p>
              <a href={props.data.link} target="_blank" rel="noreferrer">
                {props.data.text}
              </a>
            </p>
          </div>
        </div>
        <div className="Accordian__Body_Content">
          <ul>
            {props.data.description.map((bpt: string, index: number) => {
              return <li key={index}>{bpt}</li>;
            })}
          </ul>
        </div>
      </div>
    </section>
  );
};

export default Accordian;
