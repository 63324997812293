import "./Skills.scss";

const Skills: React.FC<any> = () => {
  return (
    <section className="Skills" id="skills">
      <header className="Skills__Header">
        <h1>Area’s where I shine ordered by Expertise </h1>
      </header>
      <section className="Skills__Content">
        <div>
          <h3>Frontend Development</h3>
          <div>
            <p>
              <span className="one">const</span>{" "}
              <span className="three">primary =</span>{" "}
              <span className="two"> () </span>
              <span className="one"> ={">"}</span>{" "}
              <span className="two">[</span>
            </p>
            <div className="skilllist">
              <p>"Angular/React JS",</p>
              <p>"HTML/JS/CSS",</p>
              <p>"PWA",</p>
              <p>"Typescript",</p>
              <p>"Sass/Less",</p>
              <p>"Bootstrap",</p>
              <p>"Kendo UI",</p>
              <p>"Jest",</p>
              <p>"Karma/Jasmine",</p>
              <p>"various JS libraries"</p>
            </div>
            <p>
              <span className="two">]</span>;
            </p>
          </div>
        </div>
        <div>
          <h3>Versatile Development</h3>
          <div>
            <p>
              <span className="one">public string</span>
              <span className="two">[]</span>
              <span className="three"> secondary</span>
              <span className="two">()</span>
            </p>
            <div className="codespace">
              <p>
                <span className="one">return new string []</span>{" "}
                <span className="two">{" {"}</span>
              </p>
              <div className="skilllist">
                <p>"Node JS",</p>
                <p>".NET Core",</p>
                <p>"FAST API",</p>
                <p>"Microservices",</p>
                <p>"NO SQL databases",</p>
                <p>"Docker",</p>
                <p>"Kubernetes (CKAD)",</p>
                <p>“Azure Dev Ops”,</p>
                <p>“Jira”,</p>
                <p>"SQL Code First"</p>
              </div>
              <p>
                <span className="two">{"}"}</span>
              </p>
            </div>
            <p>
              <span className="two">{"}"}</span>
            </p>
          </div>
        </div>
        <div>
          <h3>Artifical Intelligence</h3>
          <div>
            <p>
              <span className="one">def</span>
              <span className="three"> artifical_intelligence</span>
              <span className="two"> ():</span>
            </p>
            <div className="codespace">
              <p>
                <span className="one">return</span>{" "}
                <span className="two">{"["}</span>
              </p>
              <div className="skilllist">
                <p>"Python",</p>
                <p>"Gen AI - Modles",</p>
                <p>"GitHub Copilot",</p>
                <p>"Lang Chain",</p>
                <p>"TensorFlow 2.0",</p>
                <p>"Scikit Learn",</p>
                <p>"Machine Learning",</p>
                <p>"Deep Learning",</p>
                <p>"Vector DB's",</p>
                <p>"OpenSearch",</p>
                <p>"Unstructured.io"</p>
              </div>
              <p>
                <span className="two">{"]"}</span>
              </p>
            </div>
          </div>
        </div>
      </section>
    </section>
  );
};

export default Skills;
