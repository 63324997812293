import "./Timeline.scss";

const TIMELINE_DATA = [
  {
    date: new Date(2024, 3, 1),
    description: `On Going Development, AI Jeannie Plugin and Standalone application for ALM's to help teams to improve SDLC process.`,
  },
  {
    date: new Date(2024, 2, 1),
    description: `Created a plugin as part of EPAM OpenSource for Jira Cloud, AI Jeannie, to help teams to improve SDLC process.`,
  },
  {
    date: new Date(2024, 1, 1),
    description: `Working with Gen AI to improve SDLC process and reduce manual intervention.`,
  },
  {
    date: new Date(2024, 0, 1),
    description: `Joined EPAM as a Solution Architect.`,
  },
  {
    date: new Date(2023, 10, 1),
    description: `Started to look out for a new opportunity. Post a small sabbatical to spend time with family and friends to recharge.`,
  },
  {
    date: new Date(2023, 7, 1),
    description: `Last working day with accenture.`,
  },
  {
    date: new Date(2023, 4, 1),
    description: `Resignation from Accenture due to mismatch in ideology and too much red tape even for basic processes. `,
  },
  {
    date: new Date(2023, 0, 1),
    description: `Completed setup of high availability private cluster on my home server. `,
  },
  {
    date: new Date(2022, 10, 1),
    description: `Started to master Kubernetes in a plan setup a private cluster for my home server. `,
  },
  {
    date: new Date(2022, 8, 1),
    description: `Successful completion of 25 sprints and 5 releases under my guidance as SCRUM master. `,
  },
  {
    date: new Date(2022, 2, 1),
    description: `Birth of my daughter on Woman’s day, my biggest pride and joy.`,
  },
  {
    date: new Date(2021, 6, 1),
    description: `Joined Accenture as part of acquisition.`,
  },
  {
    date: new Date(2021, 5, 1),
    description: `Started a new role as SCRUM master while contributing as individual contributor. `,
  },
  {
    date: new Date(2021, 3, 1),
    description: `Mastered .Net core and Azure Service Bus. `,
  },
  {
    date: new Date(2019, 5, 1),
    description: `Started to upskill myself on .Net Core. `,
  },
  {
    date: new Date(2018, 10, 1),
    description: `Finalist on a companywide innovation summit for using SSD Convolution NN for object detection in smart helmet. `,
  },
  {
    date: new Date(2017, 2, 1),
    description: `Started my first step of journey into the beautiful world of deep learning focusing on Convolution NN's`,
  },
  {
    date: new Date(2015, 4, 1),
    description: `Joined Pramati Technologies as a UI developer. `,
  },
  {
    date: new Date(2015, 2, 1),
    description: `Resignation for Cognizant Technology Solutions, Location constraint wanted to move to Hyderabad form Chennai. `,
  },
  {
    date: new Date(2013, 10, 1),
    description: `Completed my M.Tech in Software Engineering from VITU in collaboration with Cognizant.`,
  },
  {
    date: new Date(2011, 10, 1),
    description: `Started attending M.Tech classes on my weekends from VITU in Cognizant Academy.`,
  },
  {
    date: new Date(2011, 4, 1),
    description: `Joined Cognizant Technology Solutions as a UI Developer. `,
  },
  {
    date: new Date(2011, 3, 1),
    description: `Completed B.Tech Computer Science Engineering from VITU.`,
  },
];

const Timeline: React.FC<any> = () => {
  const Milestones = TIMELINE_DATA.map(
    (item: { date: Date; description: string }, index: number) => {
      return (
        <div key={index}>
          <i className="accent-line"></i>
          <div>
            {item.date.toLocaleDateString(undefined, {
              year: "numeric",
              month: "long",
            }).split(" ").join(", ")}
          </div>
          <p>{item.description}</p>
        </div>
      );
    }
  );

  return (
    <section className="Timeline" id="timeline">
      <header>
        <h1>Timeline of major Milestones</h1>
      </header>

      <section className="Timeline__Content">{Milestones}</section>
    </section>
  );
};

export default Timeline;
