import "./Hero.scss";

const Hero: React.FC<any> = () => {
  return (
    <section className="Hero" id="about">
      <div className="Hero__Info">
        <div>
          <p>
            Hi I am <span>Jitendra Santosh Varma Kosuri</span>,
          </p>
          <p>
            I work as a <span>Solution Architect</span> and a{" "}
            <span>Mentor</span>
          </p>
          <p>
            from <span>Hyderabad</span>, India.
          </p>
        </div>
        <div className="Hero__Info__Accent"></div>
      </div>
      <div className="Hero__Bio">
        <p>
          A dedicated software engineer with a background in full-stack
          development, I specialize in frontend development but pride myself on
          being a versatile programmer who steps in wherever I am needed most.
          My passion for coding and problem-solving drives me to adapt and excel
          in various aspects of SDLC.{" "}
        </p>

        <p>
          As a Mentor, I have had the privilege of training, guiding, and
          counseling junior developers, helping them navigate their career paths
          and achieve their goals. I believe in continuous learning and personal
          growth, encouraging others to find their own paths to success rather
          than idolizing historical figures, as even the greatest heroes had
          their flaws.{" "}
        </p>

        <p>
          On a personal level, I see myself as an eternal learner, an empty page
          ready to be filled with new knowledge and experiences. While I tend to
          spend my free time as an introvert and a geek, I am also engaging and
          a good listener in social situations, providing emotional support to
          those who need it. I believe that in today’s world, true wisdom lies
          in recognizing that there is always something new to learn.
        </p>

        <p>
        I emphasize the importance of building a strong foundation over merely sharpening one’s intelligence. Like a well-crafted knife that withstands resistance, I believe resilience is key to enduring and overcoming life’s challenges.
        </p>
      </div>
      <div className="Hero__Resume">
        Download Resume:{"   "}
        <a
          download
          href="https://1drv.ms/w/c/3dbef06f4bb1edaf/Ea_tsUtv8L4ggD28BgAAAAABhIrxe0pylTjHrgc6eDNqLQ?e=4tX6VW"
          target="_blank"
          rel="noreferrer"
        >
          SantoshVarmaKosuriJ_Resume.docx
        </a>
      </div>
    </section>
  );
};

export default Hero;
