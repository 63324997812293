import "./Showcase.scss";

const SHOWCASE_DATA = [
  {
    title: "AI Jennie - Jira Cloud Plugin",
    link: "https://marketplace.atlassian.com/apps/1232950/ai-jeannie?tab=overview&hosting=cloud",
    description: `AI Jeannie harnesses generative AI capabilities to enhance productivity by rapidly transforming project definitions into detailed epics and user stories. This tool streamlines the creation of structured, Gherkin-based acceptance criteria, ensuring each user story is contextually aligned with the provided project details.`,
  },
];

const Showcase: React.FC<any> = () => {
  const cardClickHandler = (link: string) => {
    window.open(link, "_blank");
  };

  const Cards = SHOWCASE_DATA.map((items: any, index: number) => {
    return (
      <div
        key={index}
        onClick={() => {
          cardClickHandler(items.link);
        }}
      >
        <div>
          <h3>{items.title}</h3>
          <i className="link"></i>
        </div>
        <p>{items.description}</p>
      </div>
    );
  });

  return (
    <section className="Showcase">
      <header>
        <h1>Showcase</h1>
      </header>

      <section>{Cards}</section>
    </section>
  );
};

export default Showcase;
